html,body, #root, .app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  font-size: 1.1em;
}

body{
  font-size: 1.1em;
  background-image: url("./images/bg-cropped.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
}

#root{
  overflow-y: scroll;
}

.app{
  height: 100%;
}

.main-text{
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgb(44, 44, 44);
  color: rgb(245, 245, 245);
  margin: auto;
  font-size: 1.1em;
}

#landing{
  color: rgb(212, 212, 212);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
  width: 100%;
}

#landing > div{
  align-self: center;
}

#landing a{
  color: rgb(212, 212, 212);
  text-decoration: none;
}

#landing a:hover {
  text-decoration: underline;
}



@media screen and (min-width: 800px) and (orientation: landscape) {
  .main-text{
      max-width: 700px;
  }   
}



.article-wrap{
  border: 0px 1px 1px 1px solid transparent;
}

.articles{
  padding: 10px 2em 10px 2em;
}

.articles a{
  color: rgb(105, 181, 253);
}


.articles h2, h3, h4{
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.articles p{
  margin-top: 10px;
}



.auto-image{
  max-width: 100%;
}

.spaced-list li{
  margin-bottom: 20px;
}