.main-header{
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  margin: 0;
  display: flex;
  position: sticky;
  top: 0;
  /* border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 3px;
  border-color: black;
  border-style: solid; */
  background-color: rgba(0, 0, 0, 0.747);
}

.main-header > img{
  max-width: 100%;
}

.small-header img{
  max-width: 100vw;
}

.top-options{
  margin-left: auto;
  margin-right: 20px;
}

.top-options > ul{
  list-style-type: none;
  list-style: none;
  font-size: 1.5em;
}

.top-options a {
  color: rgb(218, 218, 218);
}




.top-hamburger{
  color: rgb(92, 41, 0);
  font-size: 75px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 20px;
}

.top-hamburger > div {
  align-self: center;
}

#flyoutMenu {
  width: 100vw;
  height: 100vh;
  background-color: rgb(44, 44, 44);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1000;
}
 
#flyoutMenu.hide {
  transform: translate3d(-100vw, 0, 0);
}
 
#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}
 
#flyoutMenu h2 a {
  color: rgb(219, 219, 219);
  margin-left: 15px;
  text-decoration: none;
}
 
#flyoutMenu h2 a:hover {
  text-decoration: underline;
}

@media screen and (orientation: portrait), (max-width: 700px) {
  .top-options {
    display: none;
  }  
  .big-header {
    display: none;
  } 
}

@media screen and (orientation: portrait), (max-width: 1700px) {
  .main-header{
    background-color: rgba(0, 0, 0, 0.747);
  }
}



@media screen and (orientation: landscape) and (min-width: 700px) {
  .small-header {
    display: none;
  }  
}